/*
* @Author: abhishek714
* @Date:   2025-01-29 12:30:04
* @Last Modified by:   babysoft08
* @Last Modified time: 2025-01-30 18:25:18
*/
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import xr3Icon from '../../images/XR3-img.png';
import { productData } from '../../helper/ProductData';
import rightArrowIcon from '../../images/arrow-right.png';

const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });


const options = {
    autoplay: true,
	lazyLoad: true,
	loop: true,
	margin:15,
	responsiveClass: true,
	autoHeight: true,
	autoplayTimeout: 7000,
	smartSpeed: 800,
	nav: true,
	dots: false,
	responsive: {
		0: {
		  items: 1
		}, 

		600: {
		  items: 2
		},

		1024: {
		  items: 4
		},

		1366: {
		  items: 4
		}
	}
};

class HomeProductSlider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
		
		this.renderSlides = this.renderSlides.bind(this);
	}

	componentDidMount() {
		console.log(productData);
	}

	renderSlides() {
		return(
			<React.Fragment>
				{productData.filter(product => product.is_featured).map((value, index) => (
					<React.Fragment key={index}>
						<div className="item">
							<div className="techtop-productBox">
								<div className="card">
									<div className="gray-line"></div>
									{ (value.is_show_xr === 1) && (<img src={xr3Icon} alt="XR3" className="img-fluid XR3-img"/>) }
									<div className="techtop-product-title text-center">
										<h3>{value.name}</h3>
									</div>
									<div className="techtop-product-details">
										{ value.details.length > 1 && (
											<React.Fragment>
												<ul>
													{ value.details.map(( value1, index1) => (
														<li key={index1}>{ value1 }</li>
													)) }
												</ul>
											</React.Fragment>
										) }
									</div>
									<div className="techtop-img-bottom">
										<img src={value.image} alt={value.name} className="img-fluid w-100 d-block"/>
									</div>
								</div>
								<div className="get-started-btn">
									<a href={value.link}><span>Get Started</span> <img src={rightArrowIcon} alt="arrow-right" className="img-fluid"/></a>
								</div>
							</div>
						</div>
					</React.Fragment>

				))}
			</React.Fragment>
		)
	}

	render() {
		return(
			<React.Fragment>
				<div className="owl-slider pt-4">
					<OwlCarousel className="owl-carousel owl-theme" {...options}> 
						{this.renderSlides()}
					</OwlCarousel>
				</div>
			</React.Fragment>
		)
	}
}
export default HomeProductSlider;