/*
 * @Author: Abhi Bhatt
 * @Date:   2020-06-09 18:50:28
 * @Last Modified by:   babysoft08
 * @Last Modified time: 2025-02-01 16:23:23
 */
import React from 'react';
import $ from 'jquery';
import { Link, Redirect } from 'react-router-dom';
import { urlPath, SITE_URL } from '../../constants.js';
import logo from '../../images/techtop-full-logo.svg';
import userIcon1 from '../../images/user-icon-1.png';
import userIcon2 from '../../images/user-icon-2.png';
import logoutIcon from '../../images/logout-icon-img.png';
import { Validation } from '../../validation';
import HeaderService from './../../services/HeaderService/HeaderService';
import UserService from './../../services/UserService/UserService';
import { menuData } from '../../helper/ProductMenu';
import '../../css/header.css';

const chunkArray = (arr, chunkSize) => {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  };

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuItems: [],
      fields: {},
      errors: {},
      error_message: '',
      userData : {},
      is_login:0,
      is_logout: 0,
      isLoading:0
    }
    this.renderSiteLogo = this.renderSiteLogo.bind(this);
    this.renderProductMenu = this.renderProductMenu.bind(this);
    this.renderLoginForm = this.renderLoginForm.bind(this);
    this.loginModal = this.loginModal.bind(this);
    this.logout = this.logout.bind(this);
    this.logoutRedirect = this.logoutRedirect.bind(this);
    this.headerService = new HeaderService();
    this.userService = new UserService();

  }

  handleChange(field, e){
    let { fields } = this.state; 
    const { name, value } = e.target;
    const newFormData = {
        ...fields,
        [name]: value,
    };
    this.setState({fields : newFormData});
  }

  handleSubmit(e) {
    e.preventDefault();
    const { fields } = this.state;
    let errors = Validation(fields,['email','password']);
    if (Object.keys(errors).length > 0) {
      this.setState({errors: errors});
      return;
    }
    console.log(errors);
    this.submitForm(e);
  };

  submitForm(e) {
    e.preventDefault();
    this.setState({isLoading : 1});
    $('#loginSubmit').attr('disabled', true);
      this.userService.login(this.state.fields).then((response) => {
        this.setState({isLoading : 0});
        $('#loginSubmit').attr('disabled', false);
        this.setState({ error_message: '' });
        if (response.status === 200) {
          console.log(response.data);
          if (response.data.response == 'fail') {
            this.setState({ error_message: response.data.message });
          } else {
            const user = response.data.result[0];
            
            localStorage.setItem("techtop_user_id", user.pk);
            localStorage.setItem("is_login", "1");
            
            Object.entries(user.fields).forEach(([key, value]) => {
              localStorage.setItem(`techtop_user_${key}`, typeof value === "object" ? JSON.stringify(value) : value);
            });


            this.setState((prevState) => ({
              userData: {
                ...prevState.userData, // Preserve existing state properties
                user_id: user.pk,
                first_name: user.fields.first_name,
                last_name: user.fields.last_name,
                company: user.fields.company,
                company_logo: SITE_URL + "files/company_logo/" + user.fields.company_logo,
                phone: user.fields.phone,
                cell_phone: user.fields.cell_phone,
                direct_phone: user.fields.direct_phone,
                fax: user.fields.fax,
                address: user.fields.address,
                state: user.fields.state,
                city: user.fields.city,
                zip: user.fields.zip,
              },
            }));
            
            this.setState({ is_login: 1 });
            this.setState({ is_logout: 0 });
            window.location.href = window.location.href;
          }
        }
      });
    
  }

  componentDidMount() {
    const userId = localStorage.getItem('techtop_user_id');
    const userMultiplier = localStorage.getItem('techtop_user_multiplier');

    if (userId && userId !== '') {
      if (!userMultiplier || userMultiplier === '') {
        this.logout();
        return;
      }
      this.setState((prevState) => ({
        userData: {
          ...prevState.userData, // Preserve existing userData properties
          user_id: userId,
          first_name: localStorage.getItem('techtop_user_first_name'),
          last_name: localStorage.getItem('techtop_user_last_name'),
          company: localStorage.getItem('techtop_user_company'),
          company_logo: SITE_URL + 'files/company_logo/' + localStorage.getItem('techtop_user_company_logo'),
          phone: localStorage.getItem('techtop_user_phone'),
          is_logo: (localStorage.getItem('techtop_user_company_logo')) ? true : false,
          cell_phone: localStorage.getItem('techtop_user_cell_phone'),
          direct_phone: localStorage.getItem('techtop_user_direct_phone'),
          fax: localStorage.getItem('techtop_user_fax'),
          address: localStorage.getItem('techtop_user_address'),
          state: localStorage.getItem('techtop_user_state'),
          city: localStorage.getItem('techtop_user_city'),
          zip: localStorage.getItem('techtop_user_zip'),
        }
      }));
      this.setState({ is_login: 1 });
    }

    // Display success message if applicable
    const showMessage = localStorage.getItem('show_message');
    if (showMessage && showMessage === '1') {
      $('#success_message').css('display', 'block');
      $('#success_message').html(localStorage.getItem('success_message'));
    } else {
      $('#success_message').css('display', 'none');
      $('#success_message').html('');
    }

    // Clear success message after 10 seconds
    setTimeout(() => {
      localStorage.setItem('show_message', 0);
      localStorage.setItem('success_message', '');
    }, 10000);
  }

  renderSiteLogo() {
    return (
      <React.Fragment>
        <a className="navbar-brand" href={'/'}>
          <img src={ logo } alt="Techtopind Electric Motors" />
        </a>
      </React.Fragment>
    )
  }

  renderProductMenu() {
    return (
      <React.Fragment>
        {console.log(menuData)}
        <li className="nav-item dropdown has-mega-menu" style={{ position:'static' }}>
          <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Products</a>
            {menuData.length > 1 && (
              <React.Fragment>
                <div className="dropdown-menu megamenu" style={{ width: '100%' }}>
                  <div className="container">
                    <div className="row g-3">
                      {menuData[0] && (
                        <React.Fragment>
                          <div className="col-md-8">
                            <h4>{menuData[0].name}</h4>
                            <div className="row">
                              {menuData.length > 1 && (
                                <React.Fragment>
                                  <div className="col-md-4">
                                    <div className="col-megamenu">
                                      {menuData[0].categories.filter(category => category.collapsable).map((value, index) => (
                                          <React.Fragment key={index}>
                                            <h6 className="title">{ value.name }</h6>
                                            {value.collapsable && (
                                              <ol className="sub-mega-menu">
                                                {value.subCategories.map((value1, index1) => (
                                                  <li key={index1}>
                                                    <a className="dropdown-item" href={value1.permalink}>{value1.name}</a>
                                                  </li>
                                                ))}
                                              </ol>
                                            )}
                                          </React.Fragment>
                                        )
                                      )}
                                    </div>
                                  </div>
                                  {chunkArray(menuData[0].categories.filter(category => !category.collapsable), 6).map((chunk, index) => (
                                    <div className="col-sm-4" key={index}>
                                      {chunk.map((value, subIndex) => (
                                        <a key={subIndex} className="dropdown-item" href={value.permalink}>{value.name}</a>
                                      ))}
                                    </div>
                                  ))}
                                </React.Fragment>
                              )}

                            </div>
                          </div>
                        </React.Fragment>
                      )}
                      {menuData[1] && (
                        <React.Fragment>
                          <div className="col-md-4">
                            <div className="row">
                              <div className="col-md-12">
                                <h4>{menuData[1].name}</h4>
                                {menuData[1].categories.length > 1 && (
                                  <React.Fragment>
                                    <div className="col-megamenu">
                                      {menuData[1].categories.filter(category => category.collapsable).map((value, index) => (
                                          <React.Fragment key={index}>
                                            <h6 className="title">{ value.name }</h6>
                                            {value.collapsable && (
                                              <ol className="sub-mega-menu">
                                                {value.subCategories.map((value1, index1) => (
                                                  <li key={index1}>
                                                    <a className="dropdown-item" href={value1.permalink}>{value1.name}</a>
                                                  </li>
                                                ))}
                                              </ol>
                                            )}

                                          </React.Fragment>
                                        )
                                      )}
                                      {menuData[1].categories.filter(category => !category.collapsable).length === 1 && (
                                        <React.Fragment>
                                          <ul className="list-unstyled">
                                            {menuData[1].categories.filter(category => !category.collapsable).map((value1, index1) => (
                                              <li key={index1}>
                                                <a className="dropdown-item" href={value1.permalink}>{value1.name}</a>
                                              </li>
                                            ))}
                                          </ul>
                                        </React.Fragment>
                                      )}
                                      {menuData[2] && (
                                          <React.Fragment>
                                            <h4>{menuData[2].name}</h4>
                                          </React.Fragment>
                                      )}
                                      {menuData[2].categories.filter(category => !category.collapsable).length === 1 && (
                                        <React.Fragment>

                                          <ul className="list-unstyled">
                                            {menuData[2].categories.filter(category => !category.collapsable).map((value1, index1) => (
                                              <li key={index1}>
                                                <a className="dropdown-item" href={value1.permalink}>{value1.name}</a>
                                              </li>
                                            ))}
                                          </ul>
                                        </React.Fragment>
                                      )}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
        </li>
      </React.Fragment>
    )
  }

  renderLoggedinUser() {
    let { userData } = this.state;
    let { is_login } = this.state;
    console.log(userData);
    if (is_login === 1) {
      return (
        <React.Fragment>
          <ul className="navbar-nav ms-auto right-div-login">         
            <li className="nav-item dropdown my-account"> 
              <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> <img src={userIcon1} className="img-fluid" alt="My Account"/> My Account</a>
              <ul className="dropdown-menu dropdown-menu-end">
                <li><a className="dropdown-item">{ userData.company }</a></li>
                <li><a className="dropdown-item" href={'/user/my-account'}>Account Info</a></li>
                <li><a className="dropdown-item" href={'/user/order-history'}>Order History</a></li>
                <li><a className="dropdown-item" href={'/user/open-orders'}>Open Orders</a></li>
                <li><a className="dropdown-item" href={'/track-shipment'}>Tracking</a></li>
                <li><Link className="dropdown-item" to={"#"} onClick={this.logout}><img src={logoutIcon} className="img-fluid" alt="Log Out"/> Log Out</Link></li>
              </ul>
            </li>
            {userData.is_logo && (<li className="nav-item end-img"><Link className="nav-link" to={"#"}><img src={userData.company_logo} className="img-fluid" alt=""/></Link></li>)}
          </ul>
        </React.Fragment>
      )

    } else {
      return (
        <React.Fragment>
          <ul className="navbar-nav ms-auto right-div">         
            <li className="nav-item"> <Link to={'#'} className="nav-link" data-bs-toggle="modal" data-bs-target="#loginModal">Log In</Link> </li>
            <li className="nav-item"> <a className="nav-link" href="#">Sign up</a> </li> 
          </ul>
        </React.Fragment>
      )
    }
  }



  logoutRedirect() {
    if (this.state.is_logout === 1) {
      return <Redirect to="/" />;
    }
  }


  logout() {
    this.userService.logout().then((response) => {
      console.log(response);
      this.setState({
        is_logout: 1,
        is_login: 0
      });
    });
  }

  renderLoginForm() {
    const { errors } = this.state;
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit.bind(this)} className="login-form" id="sign_up_user"
                >
          <span style={{ color: 'red' }}>
            {this.state.error_message}
          </span>
          <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="E-mail"
                    style={{ backgroundColor: '#FAFFBD' }}
                    id="email"
                    ref="email"
                    onChange={this.handleChange.bind(this, 'email')}
                    defaultValue={this.state.fields['email']}
                  />
                  {errors.email && <div className="text-danger">{errors.email}</div>}
                </div>
              </div>
          </div>
          <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    placeholder="Password"
                    style={{ backgroundColor: '#FAFFBD' }}
                    id="password"
                    ref="password"
                    onChange={this.handleChange.bind(this, 'password')}
                    defaultValue={this.state.fields['password']}
                  />
                  {errors.password && <div className="text-danger">{errors.password}</div>}
                </div>
              </div>
          </div>
          <div className="row mb-2">
              <div className="col-md-6 align-self-center">
                <div className="custom-control custom-checkbox">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                  />
                  <label className="custom-control-label" htmlFor="customCheck2">Remember me</label>
                </div>
              </div>
              <div className="col-md-6">
                <button type="submit" className="btn login-btn" disabled={this.state.isLoading}>{this.state.isLoading ? 'Please Wait...' : 'Login'}</button>
              </div>
            </div>  
            <div className="row">
              <div className="col-md-12">
                <p><Link to={"/user/forgot-password"}>Forgot Your Password?</Link></p>
                <p><Link to={"/user/confirmation"}>Didn't receive confirmation instructions?</Link></p>
                {/* it must be <a> element instead of Link component because it will redirect and close the modal */}
                <p><a href={"/support"}>Contact Support</a></p>
                <p className="sign-up-text"><a href={"/user/sign-up"}>Sign Up</a></p>
              </div>
            </div> 
        </form>
              
      </React.Fragment>
    );
  }

  loginModal() {
    return (
      <React.Fragment>
        <div className="modal login-modal-form fade" id="loginModal">
          <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Log In</h4>
                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  {this.renderLoginForm()}
                </div>
              </div>
            </div>
          </div>
      </React.Fragment>
    );
  }

  render() {
      return (
        <React.Fragment>
          {this.logoutRedirect()}
          <header className="headerSection trs"> 
            <div className="container">
              <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">
                  {this.renderSiteLogo()}
                  <div className="right-div-login d-block d-md-block d-sm-block d-lg-none d-xl-none">       
                    <div className="dropdown my-account">
                      <a className="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown"> <img src={userIcon2} className="img-fluid" alt="My Account"/> My Account</a>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li><a className="dropdown-item">Mader Electric Motor & Power Transmission</a></li>
                        <li><a className="dropdown-item" href={'/user/order-history'}>Account Info</a></li>
                        <li><a className="dropdown-item" href={'/user/order-history'}>Order History</a></li>
                        <li><a className="dropdown-item" href={'/user/open-orders'}>Open Orders</a></li>
                        <li><a className="dropdown-item" href={'/track-shipment'}>Tracking</a></li>
                        <li><Link className="dropdown-item" to={"#"} onClick={this.logout}><img src={logoutIcon} className="img-fluid" alt="Log Out"/> Log Out</Link></li>
                      </ul>
                    </div>  
                  </div>  
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav"  aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="main_nav">
                    <ul className="navbar-nav mx-auto">
                      {this.renderProductMenu()}
                      <li className="nav-item"> <a className="nav-link" href={'/downloads'}>Downloads</a> </li>
                      <li className="nav-item"> <a className="nav-link" href="#">Catalogs</a> </li>
                      <li className="nav-item"> <a className="nav-link" href={'/track-shipment'}>Tracking</a> </li>
                      <li className="nav-item"> <a className="nav-link" href={'/contact'}>Contact Us</a> </li>
                    </ul>
                    {this.renderLoggedinUser()}
                  </div> 
                </div> 
              </nav>
            </div>  
          </header>
          {this.loginModal()}
        </React.Fragment>
      )
    }
}
export default Header;