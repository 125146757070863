/*
 * @Author: Abhi Bhatt
 * @Date:   2020-06-23 15:38:25
 * @Last Modified by:   babysoft08
 * @Last Modified time: 2025-01-30 19:49:24
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { productData } from '../../helper/ProductData';
import xr3Icon from '../../images/XR3-img.png';
import rightArrowIcon from '../../images/arrow-right.png';

const renderHTML = (rawHTML) =>
  React.createElement('div', { dangerouslySetInnerHTML: { __html: rawHTML } });

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.renderProducts = this.renderProducts.bind(this);
  }

  componentDidMount() {
    
  }

  renderProducts() {
    return (
      <React.Fragment>
        { productData.map(( value, index ) => (
            <React.Fragment key={ index }>
              <div className="col-md-4 col-sm-4 col-lg-3 col-xl-3">
                <div className="techtop-productBox">
                  <div className="card">
                    <div className="gray-line"></div>
                    { (value.is_show_xr === 1) && (<img src={xr3Icon} alt="XR3" className="img-fluid XR3-img"/>) }
                      <div className="techtop-product-title text-center">
                        <h3>{value.name}</h3>
                      </div> 
                      <div className="techtop-product-details">
                        { value.details.length > 1 && (
                          <React.Fragment>
                            <ul>
                              { value.details.map(( value1, index1) => (
                                <li key={index1}>{ value1 }</li>
                              )) }
                            </ul>
                          </React.Fragment>
                        ) }
                      </div>
                      <div className="techtop-img-bottom">
                        <img src={value.image} alt={value.name} className="img-fluid w-100 d-block"/>
                      </div>
                    </div>
                  <div className="get-started-btn">
                    <a href={value.link}><span>Get Started</span> <img src={rightArrowIcon} alt="arrow-right" className="img-fluid"/></a>
                  </div>
                </div>
              </div> 
            </React.Fragment>
        )) }
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        <main className="techtop-middle-div">
          <section className="techtop-view-product-section pt-5">
            <div className="container">
              <div className="row">
                { this.renderProducts() }
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    )
  }
}

export default Product;