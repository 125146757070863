/*
* @Author: babysoft08
* @Date:   2025-01-30 18:26:32
* @Last Modified by:   babysoft08
* @Last Modified time: 2025-01-30 18:27:26
*/
export const menuData = [
  {
    name: 'NEMA Motors',
    categories: [
      {
        name: 'General Purpose Three Phase',
        collapsable: true,
        subCategories: [
          {
            name: 'Cast Iron',
            permalink: '/products/three-phase-cast-iron',
          },
          {
            name: 'Aluminum',
            permalink: '/products/gx3-three-phase-aluminum',
          },
          {
            name: 'Cast Iron & Rolled Steel ODP',
            permalink: '/products/three-phase-open-enclosure',
          },
        ],
      },
      {
        name: 'General Purpose Single Phase',
        collapsable: true,
        subCategories: [
          { name: 'Aluminum- Cobra Line', permalink: '/products/cobra-single-phase-aluminum'},  
          { name: 'Farm Duty',            permalink: '/products/farm-duty'},
        ],
      },
      {
        name: 'JM/JP Pump Motors',
        permalink: '/products/premium-jm-jp',
      },
      {
        name: 'Stainless Steel',
        permalink: '/products/stainless-steel',
      },
      {
        name: 'Crusher Duty',
        permalink: '/products/crusher-duty',
      },
      {
        name: 'IEEE 841',
        permalink: '/products/ieee-841	',
      },
      {
        name: 'Explosion Proof',
        permalink: '/products/explosion-proof',
      },
      {
        name: 'Aerator Motor',
        permalink: '/products/aerator',
      },
      {
        name: 'Permanent Magnet DC Motors',
        permalink: '/products/pm-dc-motor',
      },
      {
        name: 'Phase Converter Motors',
        permalink: '/products/phase-converter-motor',
      },
      {
        name: 'Cooling Tower',
        permalink: '/products/cooling-tower-xr3',
      },
      {
        name: 'Washdown Duty',
        permalink: '/products/washdown',
      },
      {
        name: 'Oil Pump',
        permalink: '/products/oil-pump',
      },
      {
        name: 'Brake Motor NEMA',
        permalink: '/products/brake-motor-nema',
      },
    ],
  },
  {
    name: 'IEC Motor',
    categories: [
      {
        name: 'General Purpose',
        collapsable: true,
        subCategories: [
          { name: 'Single Phase', permalink: 'metric-iec-single-phase' },  
          { name: 'Three Phase',  permalink: '/products/metric-iec' },
        ],
      },
      {
        name: 'Brake Motor IEC',
        permalink: '/products/brake-motor-iec',  
      },
    ],
  },
  {
    name: 'Motor Accessories',
    categories: [
      {
        name: 'Constant Velocity Fan Kit',
        permalink: '/products/constant-velocity-fan-kit',
      },
    ],
  },
];