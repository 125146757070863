/*
* @Author: Abhi Bhatt
* @Date:   2020-06-17 15:10:57
* @Last Modified by:   babysoft08
* @Last Modified time: 2025-02-01 16:02:42
*/
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import banner1 from '../../images/banner/banner1.png';
import banner3 from '../../images/banner/banner3.jpg';
import arcLogo from '../../images/arc-logo-img.png';

const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });


const options = {
    margin: 15,
    responsiveClass: true,
    nav: true,
    dots: false,
    //autoplay: false,
    //navText: ["Prev", "Next"],
    //smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
            nav: false
        },
        600: {
        	items: 1,
        	nav: false
        },
        1000: {
        	items: 1,
        	nav: false,
        	loop: false
        }
    },
};

class HomeHeroSlider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			
		};
		this.renderBannerImages = this.renderBannerImages.bind(this);
		this.renderSlider = this.renderSlider.bind(this);
	}

	componentDidMount() {
	}

	renderBannerImages(){
		return(
			<React.Fragment>
				<div className="carousel-item active"> 
					<img src={banner1} className="d-block w-100 img-fluid mx-auto" alt="banner"/>
					<div className="carousel-caption">
						<h1>Introducing Techtop's<br/> Next-Gen Motors: <br/>Built for Efficiency and Power!</h1>
						<ul className="techtop-list">
							<li>SUPER Premium Efficiency Design</li>
							<li>Class H Insulation</li>
							<li><img src={arcLogo} className="img-fluid" alt="ARC Arrestor"/> <span>Shaft Grounding Installed</span></li>
						</ul>
					</div>
				</div> 
				<div className="carousel-item">
					<img src={banner3} className="d-block w-100 img-fluid mx-auto" alt="banner"/>
					<div className="carousel-caption">
						<h2>Introducing Techtop's<br/> Next-Gen Motors: <br/>Built for Efficiency and Power!</h2>
						<ul className="techtop-list">
							<li>SUPER Premium Efficiency Design</li>
							<li>Class H Insulation</li>
							<li><img src={arcLogo} className="img-fluid" alt="ARC Arrestor"/> <span>Shaft Grounding Installed</span></li>
						</ul>
					</div>
				</div>
			</React.Fragment>
		)
	}

	renderSlider() {
		return (
			<React.Fragment>
				<div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
					<div className="carousel-inner">
						{this.renderBannerImages()}
					</div>
					<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="sr-only">Previous</span> </button>
					<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="sr-only">Next</span> </button>
				</div>
			</React.Fragment>
		);
	}

	render() {
		return (
			<React.Fragment>
				{this.renderSlider()}
			</React.Fragment>
		)
	}


}

export default HomeHeroSlider;