import React from 'react';
import { Link } from 'react-router-dom';
import fullLogo from '../../images/techtop-full-logo.svg';


class Footer extends React.Component {
  render() {
    return (
      <footer className="techtop-footer-div py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <img src={fullLogo} className="img-fluid d-block" alt="Techtop"/>
              <p className="m-0"><strong>Motors & Relationships Built to Last</strong></p>
              <p className="m-0">Quality is the main ingredient</p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
