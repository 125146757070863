import productCastIron from '../images/products-img/cast-iron.png';
import productAluminum from '../images/products-img/aluminum.png';
import productAluminumCobra3Phase from '../images/products-img/aluminum-cobra-3-phase.png';
import productAluminumCobraSinglePhase from '../images/products-img/aluminum-cobra-single-phase.png';
import productExplosionProof from '../images/products-img/explosion-proof.png';
import productAerator from '../images/products-img/aerator.png';
import productPhaseConverter from '../images/products-img/phase-converter.png';
import productStainlessSteel from '../images/products-img/stainless-steel.png';
import productMetricIec from '../images/products-img/metric-iec.png';
import productMetricIecSingle from '../images/products-img/metric-iec-single.png';
import productFarmDutySingle from '../images/products-img/farm-duty-single.png';
import productIeee841 from '../images/products-img/ieee-841.png';
import productOpenDrip from '../images/products-img/open-drip.png';
import productCrusherDuty from '../images/products-img/crusher-duty.png';
import productDcMotor from '../images/products-img/dc-motor.png';
import productCoolingTower from '../images/products-img/cooling-tower.png';
import productWashdown from '../images/products-img/washdown.png';
import productOilPump from '../images/products-img/oil-pump.png';
import productBreakMotors from '../images/products-img/brake-motors.png';
import productJmJpPump from '../images/products-img/jm-jp-pump.png';
import xr3Icon from '../images/XR3-img.png';


export const productData = [
  {
    "name": "Cast Iron",
    "details": [
      "HP: 1–500",
      "Frame: 143T– 449T",
      "Pole: 2,4,6",
      "Volt: 230/460V",
      "RPM: 3600/1800/1200",
      "Body: T, TC"
    ],
    "image": productCastIron,
    "link": "/products/cobra-single-phase-aluminum",
    "is_featured":1,
    "is_show_xr":1
  },
  {
    "name": "Aluminum",
    "details": [
      "HP: 1–30",
      "Frame: 143T–286T",
      "Pole: 2,4,6",
      "Volt: 230/460V",
      "RPM: 3600/1800/1200",
      "Encl: (TEFC) IP 55"
    ],
    "image": productAluminum,
    "link": "/products/cobra-single-phase-aluminum",
    "is_featured":1,
    "is_show_xr":0
  },
  {
    "name": "Aluminum Cobra 3-Phase",
    "details": [
      "HP: 0.33 - 3",
      "Frame: 56C",
      "Pole: 2,4",
      "Volt: 230/460V",
      "RPM: 3600/1800"
    ],
    "image": productAluminumCobra3Phase,
    "link": "/products/cobra-single-phase-aluminum",
    "is_featured":1,
    "is_show_xr":1
  },
  {
    "name": "Aluminum Cobra Single Phase",
    "details": [
      "HP: 0.33 - 10",
      "Frame: 56C–215T",
      "Pole: 2,4",
      "Volt: 115/208-230",
      "RPM: 3600/1800"
    ],
    "image": productAluminumCobraSinglePhase,
    "link": "/products/cobra-single-phase-aluminum",
    "is_featured":1,
    "is_show_xr":0
  },
  {
    "name": "Explosion Proof",
    "details": [
      "Venue Selection",
      "Basic Decor",
      "Catering for 50 Guests",
      "Event Coordination"
    ],
    "image": productExplosionProof,
    "link": "/products/explosion-proof",
    "is_featured":1,
    "is_show_xr":0
  },
  {
    "name": "Aerator",
    "details": [
      "Venue Selection",
      "Decor & Theme Design",
      "Catering for 100 Guests",
      "Event Coordination"
    ],
    "image": productAerator,
    "link": "/products/aerator",
    "is_show_xr":0
  },
  {
    "name": "Phase Converter",
    "details": [
      "Venue Selection",
      "Luxury Decor & Theme Design",
      "Catering for 300 Guests",
      "Event Coordination",
      "Entertainment Booking (Live Band)",
      "Photography & Videography Services"
    ],
    "image": productPhaseConverter,
    "link": "/products/phase-converter-motor",
    "is_featured":0,
    "is_show_xr":0
  },
  {
      "name": "Washdown",
      "details": [
        "Feature 1: Venue Selection",
        "Feature 2: Premium Decor & Theme Design",
        "Feature 3: Catering for 200 Guests",
        "Feature 4: Entertainment Booking (DJ or Band)",
        "Feature 5: Photography & Videography Services"
      ],
      "image": productWashdown,
      "link": "https://techtopind.com/products/washdown"
    },
    {
      "name": "Oil Pump",
      "details": [
        "Feature 1: Venue Selection",
        "Feature 2: Basic Decor",
        "Feature 3: Catering for 50 Guests",
        "Feature 4: Event Coordination"
      ],
      "image": productOilPump,
      "link": "https://techtopind.com/products/oil-pump"
    },
    {
      "name": "Brake Motors",
      "details": [
        "Feature 1: Venue Selection",
        "Feature 2: Decor & Theme Design",
        "Feature 3: Catering for 50 Guests",
        "Feature 4: Event Coordination"
      ],
      "image": productBreakMotors,
      "link": "https://techtopind.com/products/brake-motor-nema"
    },
    {
      "name": "JM/JP Pump",
      "details": [
        "Feature 1: Venue Selection",
        "Feature 2: Luxury Decor & Theme Design",
        "Feature 3: Catering for 300 Guests",
        "Feature 4: Event Coordination",
        "Feature 5: Entertainment Booking (Live Band)",
        "Feature 6: Photography & Videography Services"
      ],
      "image": productJmJpPump,
      "link": "https://techtopind.com/products/premium-jm-jp"
    },
  {
    "name": "Stainless Steel",
    "details": [
      "Venue Selection",
      "Premium Decor & Theme Design",
      "Catering for 200 Guests",
      "Entertainment Booking (DJ or Band)",
      "Photography & Videography Services"
    ],
    "image": productStainlessSteel,
    "link": "/products/stainless-steel",
    "is_featured":0,
    "is_show_xr":0
  },
  {
    "name": "Metric - IEC",
    "details": [
      "Venue Selection",
      "Basic Decor",
      "Catering for 50 Guests",
      "Event Coordination"
    ],
    "image": productMetricIec,
    "link": "/products/metric-iec",
    "is_featured":0,
    "is_show_xr":0
  },
  {
    "name": "Metric - IEC Single Phase",
    "details": [
      "Venue Selection",
      "Decor & Theme Design",
      "Catering for 100 Guests",
      "Event Coordination"
    ],
    "image": productMetricIecSingle,
    "link": "/products/metric-iec-single-phase",
    "is_featured":0,
    "is_show_xr":0
  },
  {
    "name": "Farm Duty Single Phase",
    "details": [
      "Venue Selection",
      "Luxury Decor & Theme Design",
      "Catering for 300 Guests",
      "Event Coordination",
      "Entertainment Booking (Live Band)",
      "Photography & Videography Services"
    ],
    "image": productFarmDutySingle,
    "link": "/products/farm-duty",
    "is_featured":0,
    "is_show_xr":0
  },
  {
    "name": "IEEE 841",
    "details": [
      "Venue Selection",
      "Premium Decor & Theme Design",
      "Catering for 200 Guests",
      "Entertainment Booking (DJ or Band)",
      "Photography & Videography Services"
    ],
    "image": productIeee841,
    "link": "/products/ieee-841",
    "is_featured":0,
    "is_show_xr":0
  },
  {
    "name": "Open Drip",
    "details": [
      "Venue Selection",
      "Basic Decor",
      "Catering for 50 Guests",
      "Event Coordination"
    ],
    "image": productOpenDrip,
    "link": "/products/three-phase-open-enclosure",
    "is_featured":0,
    "is_show_xr":1
  },
  {
    "name": "Crusher Duty",
    "details": [
      "Venue Selection",
      "Decor & Theme Design",
      "Catering for 100 Guests",
      "Event Coordination"
    ],
    "image": productCrusherDuty,
    "link": "/products/crusher-duty",
    "is_featured":0,
    "is_show_xr":0
  },
  {
    "name": "DC Motor",
    "details": [
      "Venue Selection",
      "Decor & Theme Design",
      "Catering for 100 Guests",
      "Event Coordination"
    ],
    "image": productDcMotor,
    "link": "/products/pm-dc-motor",
    "is_featured":0,
    "is_show_xr":0
  },
  {
    "name": "Cooling Tower",
    "details": [
      "Venue Selection",
      "Premium Decor & Theme Design",
      "Catering for 200 Guests",
      "Entertainment Booking (DJ or Band)"
    ],
    "image": productCoolingTower,
    "link": "/products/cooling-tower-xr3",
    "is_featured":0,
    "is_show_xr":0
  }
];